import { Button, Dialog, FormControl, IconButton, styled, Switch, SwitchProps, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { useSnackbar, withSnackbar } from "notistack";
import AutocompleteCities from "elements/AutocompleteCities";
import AutocompletePlans from "elements/AutocompletePlans";
import ISwitch from "elements/ISwitch";
import CameraAltIcon from '@mui/icons-material/CameraAlt';

//TODAY DATE FORMATED MM/DD/YYYY
const today = new Date('2014-08-18T21:11:54')


export default function CompanyEditor(props : any) {

  const {
    setLoading,
    openEditor,
    setOpenEditor,
    company_id,
    loadList
  } = props;


  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */



  // company_id	company_name	email	nit	address	city_id	manager_name	manager_phone		
  const navigate = useNavigate();
  const defaultValues: any = {
    company_id: company_id? company_id : "null",
    company_name: "",
    email: "",
    nit: "",
    address: "",
    city_id: null,
    manager_name: "",
    manager_phone: "",
    plan_id: null,
    restrictions: {
      allowOutOfStockSale: false,
      geolocationOrders: false, // Changed variable name
      geolocationRoutes: false, // Changed variable name
      geolocationOrdersPrecision: 0, // New input
      geolocationRoutesPrecision: 0, // New input
      allowSaleWhenOutOfStock: false, // New switch
    },
    image: "",
    is_active: true,
  };

  
  
  const [formValues, setFormValues] = useState(defaultValues)



  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

    const [files, setFiles] = useState<any>([]);


  /*=======================================
  SUBMIT FORM
  =======================================*/
  const handleSubmit = () => {
    setLoading(true)

    const body = new FormData();
    if (files){
      console.log("filesxxx", files)
      body.append("image", files);
    }
    body.append("formValues", JSON.stringify(formValues));

    axios.post(ProjectConfig.api_url+"/companies/addedit", body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);

        if(response.data.status === "success"){
          enqueueSnackbar('Administrador guardado con éxito', { variant: 'success' });
          setOpenEditor(false)
          loadList();
        }else{
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
          
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false)
      });
  };



  /**/

  useEffect(() => {
    if (company_id) {
      
      axios.get(ProjectConfig.api_url + "/companies/details", {
          params: {
            company_id: company_id,
          },
        })
        .then((response) => {
          console.log("formValues res", response.data)

          //convert 

          setFormValues(response.data)
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
    else{
      setFormValues(defaultValues);
    }
    setFiles([]); 
  }, [company_id, openEditor]);

  console.log("formValues", formValues)


  return (
      <>
        <Dialog 
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="DialogContainer" style={{maxWidth:'502px'}}>
              <div className="ModuleWrapper">
                  <div className="boxContainerHeader">
                      <div className="boxContainerTitle">
                          <h2 className="h2_title">Empresa</h2>
                          <span className="h2_subtitle">Editar</span>
                      </div>

                      
                  </div>

                  <div id="userForm">
                    <div className="userForm_right">
                        <div className='input_group'>

                        <div className="drawerImagesContainer">
                            
                            <div className="drawerImagesContainerDiv"
                              onDrop={(e) => {
                                  e.preventDefault();
                                  // UPDATE FILE
                                  const files = e.dataTransfer.files;
                                  setFiles(files[0]);
                                  const fileArray = Array.from(files).map((file:any) => URL.createObjectURL(file));
                                  setFormValues({
                                    ...formValues,
                                    image: fileArray[0],
                                  });
                                  e.currentTarget.classList.remove("dropzone_active");
                                }
                                } 
                                onDragOver={
                                  (e) => {
                                    e.preventDefault();
                                    e.currentTarget.classList.add("dropzone_active");
                                  }
                                  
                                }
                                onDragLeave={
                                  (e) => {
                                    e.preventDefault();
                                    e.currentTarget.classList.remove("dropzone_active");
                                  }
                                }
                            
                              >
                              {formValues.image && (
                                <img src={formValues.image}  />
                              )}
                              
                              <label id='dropFileDiv' className={formValues.image && ('showOnlyOnHover')}>
                                <IconButton 
                                  aria-label="delete"
                                  size="small"
                                  className="addFile_button"
                                  component="span"
                                >
                                  <CameraAltIcon/>
                                  <input 
                                    type="file"
                                    multiple 
                                    hidden 
                                    onChange={(e) => {
                                      const files = e.target.files;
                                      if (files) {
                                          
                                          // UPDATE FILE
                                          setFiles(files[0]);
                                          const fileArray = Array.from(files).map((file:any) => URL.createObjectURL(file));
                                          setFormValues({
                                            ...formValues,
                                            image: fileArray[0],
                                          });
    
                                        }
    
                                    }}
                                  />
                                  
                                </IconButton>
                              </label>
                              
                            </div>
    
                          </div>
                          
                            <h2>Información empresa</h2>
                            <div className='input_group'>

                                <div className='input_row'>

                                <TextField 
                                  id="company_name"
                                  name="company_name"
                                  label="Nombre empresa"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.company_name}
                                  onChange={handleInputChange}
                                />
                                

                                <TextField
                                  id="nit"
                                  name="nit"
                                  label="NIT"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.nit}
                                  onChange={handleInputChange}
                              />
                              

                              </div>

                              <TextField
                                  id="email"
                                  name="email"
                                  label="Email"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.email}
                                  onChange={handleInputChange}
                                />

                              
                            </div>

                            <div className='input_group'>
                              <h2>Persona de contacto</h2>
                              <div className="input_row">
                                <TextField
                                  id="manager_name"
                                  name="manager_name"
                                  label="Nombre"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.manager_name}
                                  onChange={handleInputChange}
                                />

                                <TextField
                                  id="manager_phone"
                                  name="manager_phone"
                                  label="Teléfono"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.manager_phone}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>


                            <div className='input_group'>
                              <h2>Dirección</h2>
                              <div className="input_row">
                                <TextField
                                  id="address"
                                  name="address"
                                  label="Dirección"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.address}
                                  onChange={handleInputChange}
                                />
                              </div>

                              <AutocompleteCities
                                value={formValues.city_id} 
                                onChange={(e: any) => {
                                  setFormValues({
                                    ...formValues,
                                    city_id: e.target.value,
                                  });
                                }} 
                              />

                              <AutocompletePlans
                                value={formValues.plan_id} 
                                onChange={(e: any) => {
                                  setFormValues({
                                    ...formValues,
                                    plan_id: e.target.value,
                                  });
                                }}
                              />

                            </div>

                            <div className='input_group'>
                              <h2>Restricciones</h2>
                              <div className="input_row flex_wrap">
                                {/* restrictions.kilos */}
                                <ISwitch
                                  label="Kilos"
                                  checked={formValues.restrictions?.kilos !== undefined ? formValues.restrictions.kilos : false}
                                  onChange={(e: any) => {
                                    setFormValues({
                                      ...formValues,
                                      restrictions: {
                                        ...formValues.restrictions,
                                        kilos: e.target.checked,
                                      },
                                    });
                                  }}
                                />

                                {/* restrictions.square_meter */}
                                <ISwitch
                                  label="M2"
                                  checked={formValues.restrictions?.square_meter !== undefined ? formValues.restrictions.square_meter : false}
                                  onChange={(e: any) => {
                                    setFormValues({
                                      ...formValues,
                                      restrictions: {
                                        ...formValues.restrictions,
                                        square_meter: e.target.checked,
                                      },
                                    });
                                  }}
                                />

                                {/* restrictions.allowOutOfStockSale */}
                                <ISwitch
                                  label="Permitir venta sin stock"
                                  checked={formValues.restrictions?.allowOutOfStockSale !== undefined ? formValues.restrictions.allowOutOfStockSale : false}
                                  onChange={(e: any) => {
                                    setFormValues({
                                      ...formValues,
                                      restrictions: {
                                        ...formValues.restrictions,
                                        allowOutOfStockSale: e.target.checked,
                                      },
                                    });
                                  }}
                                />



                              </div>
                                

                              <h2>Geolocalización</h2>
                                <ISwitch
                                  label="Pedidos Geolocalización"
                                  checked={formValues.restrictions?.geolocationOrders !== undefined ? formValues.restrictions.geolocationOrders : false}
                                  onChange={(e: any) => {
                                    const checked = e.target.checked;
                                    setFormValues({
                                      ...formValues,
                                      restrictions: {
                                        ...formValues.restrictions,
                                        geolocationOrders: checked,
                                        geolocationOrdersPrecision: checked ? formValues.restrictions.geolocationOrdersPrecision : 0,
                                      },
                                    });
                                  }}
                                />
                                {formValues.restrictions.geolocationOrders && (
                                  <TextField
                                    id="geolocation_orders_precision"
                                    name="geolocation_orders_precision"
                                    label="Precisión Pedidos Geolocalización"
                                    type="number"
                                    required
                                    fullWidth
                                    value={formValues.restrictions.geolocationOrdersPrecision}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      setFormValues({
                                        ...formValues,
                                        restrictions: {
                                          ...formValues.restrictions,
                                          geolocationOrdersPrecision: value,
                                        },
                                      });
                                    }}
                                  />
                                )}
                                
                                <ISwitch
                                  label="Rutas Geolocalización"
                                  checked={formValues.restrictions?.geolocationRoutes !== undefined ? formValues.restrictions.geolocationRoutes : false}
                                  onChange={(e: any) => {
                                    const checked = e.target.checked;
                                    setFormValues({
                                      ...formValues,
                                      restrictions: {
                                        ...formValues.restrictions,
                                        geolocationRoutes: checked,
                                        geolocationRoutesPrecision: checked ? formValues.restrictions.geolocationRoutesPrecision : 0,
                                      },
                                    });
                                  }}
                                />
                                {formValues.restrictions.geolocationRoutes && (
                                  <TextField
                                    id="geolocation_routes_precision"
                                    name="geolocation_routes_precision"
                                    label="Precisión Rutas Geolocalización"
                                    type="number"
                                    required
                                    fullWidth
                                    value={formValues.restrictions.geolocationRoutesPrecision}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      setFormValues({
                                        ...formValues,
                                        restrictions: {
                                          ...formValues.restrictions,
                                          geolocationRoutesPrecision: value,
                                        },
                                      });
                                    }}
                                  />
                                )}
                            </div>


                            

                            <div className='input_group'>
                              <h2>Estado</h2>
                              <div className="input_row">
                                <ISwitch
                                  label="Activo"
                                  checked={formValues.is_active}
                                  onChange={(e: any) => {
                                    setFormValues({
                                      ...formValues,
                                      is_active: e.target.checked,
                                    });
                                  }}
                                />
                              </div>

                              </div>


                        </div>
                    </div>
                </div>
              </div>
          </div>

          <div className="dialogButtons">
            <Button
                onClick={() => setOpenEditor(false)}
                variant="outlined"
                className="button_2"
            >
              Cancelar
            </Button>

            <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
          </div>
        </Dialog>
      </>
  );
}
