import axios from 'axios';
import { useEffect, useState } from 'react';
import Box from 'elements/Box';
import { Button, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import AlertDialog from 'elements/AlertDialog';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useSnackbar } from 'notistack';

import 'assets/css/main.css'; 

import BrandsEditor from './ClientsEditor';
import { ApiRequest } from 'GlobalFunctions';

export default function Clients(props: any) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = props;

  useEffect(() => {
    setLoading(false);
  }, []);
  
  const [list, setList] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const currentPage = parseInt(localStorage.getItem('brandPage') || "1");
  const [page, setPage] = useState(currentPage);
  const [openEditor, setOpenEditor] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadList();
  }, [page]);

  const loadList = async () => {
    setLoading(true);

    // axios.get(ProjectConfig.api_url + "/clients/list", {
    //   headers: {
    //     "ltkn": localStorage.getItem('ltkn')
    //   },
    //   params: {
    //     page: page,
    //   }
    // })
    // .then((response) => {
    //   setList(response.data.list);
    //   setTotalPages(response.data.total_pages);
    //   setLoading(false);
    // })
    // .catch((err) => {
    //   console.log(err.response.data);
    // })
    // .finally(() => {
    //   setLoading(false);
    // });
    await ApiRequest({
      url: "/clients/list",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      query: {
        page: page,
      },
      setResponse: (response: any) => {
        setList(response.list);
        setTotalPages(response.total_pages);
        setLoading(false);
      },
    });

    localStorage.setItem('clientsPage', page.toString());
  };

  const handleDelete = (client_id: number) => {
    setActiveItem(client_id);
    setOpenAlert(true);
  };

  const deleteItem = () => {
    const body = {
      client_id: activeItem
    };

    axios.post(ProjectConfig.api_url + "/clients/deleteItem", body) // Adjust endpoint if needed
      .then((response) => {
        if (response.data.status === "success") {
          enqueueSnackbar('Marca eliminada con éxito', { variant: 'success' });
          loadList();
        }
      })
      .catch((err) => {
        console.log(err.data);
      })
      .finally(() => {
        setOpenAlert(false);
      });
  };

  return (
    <div id='ClientList' className='ModuleContainer'>
      <div className="ModuleWrapper">
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h2 className="h2_title">Clientes</h2>
            <span className="h2_subtitle">Lista de clientes</span>
          </div>
          <div className="boxContainerActions">
            {/* <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveItem(null);
                setOpenEditor(true); 
              }}
              startIcon={<AddIcon />}
            >
              
            </Button> */}
          </div>
        </div>

        <Box id='ClientListIn' className='box100'>
          <TableContainer>
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>CLIENTE</TableCell>
                  <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>UBICACIÓN</TableCell>
                  <TableCell sx={{ color: '#a8a8a8', borderBottom: "none", justifyContent: 'flex-end' }}></TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((item, i) => (
                  <TableRow key={i} hover role="checkbox">
                    <TableCell>
                        <span className='flex_title'>{item.business_name}</span>
                        <span className='flex_subtitle'>
                          {item.client_name} {item.client_name2} {item.client_last_name} {item.client_last_name2}
                        </span>
                    </TableCell>

                    <TableCell>
                        <span className='flex_title'>{item.neighborhood_name}</span>
                        <span className='flex_subtitle'>
                          {item.client_address}
                        </span>
                    </TableCell>

                      
                    <TableCell align="right">
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        style={{ marginRight: "10px" }}
                        startIcon={<ModeEditIcon />}
                        onClick={() => {
                          setActiveItem(item.client_id);
                          setOpenEditor(true);
                        }}
                      >
                        Editar
                      </Button>
                      <IconButton aria-label="delete" color="primary" onClick={() => handleDelete(item.client_id)}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => setPage(value)}
            color="primary"
            style={{ marginTop: "20px", alignSelf: "center" }}
          />
        </Box>
      </div>

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="¿Está seguro que desea eliminar esta marca?"
        send={deleteItem}
      />

      <BrandsEditor
        setLoading={setLoading}
        openEditor={openEditor}
        setOpenEditor={setOpenEditor}
        client_id={activeItem}
        loadList={loadList}
      />
    </div>
  );
};
